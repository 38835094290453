var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "largeModal",
        {
          attrs: { display: _vm.display, background: "#ffffff" },
          on: {
            close: function ($event) {
              _vm.showReassurePrompt = true
            },
          },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _vm.details.length
                    ? _c(
                        "h5",
                        {
                          staticClass:
                            "md:text-2xl text-xl text-center font-bold primary-text leading-10 w-full",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.details[_vm.currentActiveForm].label) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "div",
            { staticClass: "lg:w-3/5 mx-auto" },
            [
              _c("p", { staticClass: "text-center text-grey md:text-lg" }, [
                _vm._v(_vm._s(_vm.details[_vm.currentActiveForm].description)),
              ]),
              _c("div", { staticClass: "mt-4" }, [
                _c(
                  "div",
                  _vm._l(
                    _vm.details[_vm.currentActiveForm].sections,
                    function (details) {
                      return _c(
                        "div",
                        { key: details.label },
                        [
                          _c("p", { staticClass: "text-grey text-xs my-4" }, [
                            _vm._v(_vm._s(details.label)),
                          ]),
                          _vm._l(details.fields, function (field) {
                            return _c(
                              "div",
                              { key: field.id, staticClass: "mt-4" },
                              [
                                field.field.inputType === _vm.formTypes.text ||
                                field.field.inputType ===
                                  _vm.formTypes.number ||
                                field.field.inputType === _vm.formTypes.email
                                  ? _c("Input", {
                                      attrs: {
                                        width: "w-full",
                                        placeholder: field.field.hintText,
                                        label: field.field.label,
                                        required: field.field.required,
                                        type:
                                          field.field.inputType ===
                                          _vm.formTypes.number
                                            ? "number"
                                            : field.field.inputType.toLowerCase(),
                                        errorText: _vm.handleErrorText(field),
                                        validation:
                                          _vm.handleInputValidation(field),
                                      },
                                      model: {
                                        value: _vm.userData[field.id],
                                        callback: function ($$v) {
                                          _vm.$set(_vm.userData, field.id, $$v)
                                        },
                                        expression: "userData[field.id]",
                                      },
                                    })
                                  : _vm._e(),
                                field.field.inputType === _vm.formTypes.phone
                                  ? _c("TelInput", {
                                      staticClass: "mt-4",
                                      attrs: {
                                        width: "w-full",
                                        required: field.field.required,
                                        placeholder: field.field.hintText,
                                        label: field.field.label,
                                        errorText: _vm.handleErrorText(field),
                                      },
                                      model: {
                                        value: _vm.userData[field.id],
                                        callback: function ($$v) {
                                          _vm.$set(_vm.userData, field.id, $$v)
                                        },
                                        expression: "userData[field.id]",
                                      },
                                    })
                                  : _vm._e(),
                                field.field.inputType === _vm.formTypes.amount
                                  ? _c("CurrencyInput", {
                                      attrs: {
                                        width: "w-full",
                                        placeholder: field.field.hintText,
                                        label: field.field.label,
                                        required: field.field.required,
                                        errorText: _vm.handleErrorText(field),
                                      },
                                      model: {
                                        value: _vm.userData[field.id],
                                        callback: function ($$v) {
                                          _vm.$set(_vm.userData, field.id, $$v)
                                        },
                                        expression: "userData[field.id]",
                                      },
                                    })
                                  : _vm._e(),
                                field.field.inputType === _vm.formTypes.upload
                                  ? _c("Upload", {
                                      attrs: {
                                        text: field.field.hintText,
                                        label: field.field.label,
                                        requird: field.field.required,
                                        fileType: ["png", "jpg", "jpeg"],
                                      },
                                      model: {
                                        value: _vm.userData[field.id],
                                        callback: function ($$v) {
                                          _vm.$set(_vm.userData, field.id, $$v)
                                        },
                                        expression: "userData[field.id]",
                                      },
                                    })
                                  : _vm._e(),
                                field.field.inputType === _vm.formTypes.option
                                  ? _c(
                                      "div",
                                      { key: field.field.label },
                                      [
                                        field.field.multiSelect
                                          ? _c("MultiSelect", {
                                              key: field.field.label,
                                              attrs: {
                                                options: field.field.options,
                                                formLabel: field.field.label,
                                                placeholder:
                                                  field.field.hintText,
                                                required: field.field.required,
                                                reduce: function (item) {
                                                  return item.id
                                                },
                                              },
                                              model: {
                                                value: _vm.userData[field.id],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.userData,
                                                    field.id,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "userData[field.id]",
                                              },
                                            })
                                          : _c("Select", {
                                              attrs: {
                                                value: _vm.userData[field.id],
                                                returnValue: "array",
                                                options: field.field.options,
                                                formLabel: field.field.label,
                                                placeholder:
                                                  field.field.hintText,
                                                reduce: function (item) {
                                                  return item.id
                                                },
                                                required: field.field.required,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.handleSelectInput(
                                                    $event,
                                                    field.id
                                                  )
                                                },
                                              },
                                            }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                field.field.inputType === _vm.formTypes.address
                                  ? _c(
                                      "div",
                                      [
                                        _c("Input", {
                                          staticClass: "mt-4",
                                          attrs: {
                                            label: field.field.label,
                                            placeholder: field.field.hintText,
                                            id: "address",
                                            type: "text",
                                            width: "w-full",
                                            required: field.field.required,
                                          },
                                          on: {
                                            blur: function ($event) {
                                              return _vm.validateAddress()
                                            },
                                            focus: function ($event) {
                                              return _vm.handleFocus(field.id)
                                            },
                                            load: _vm.googleAutocomplete,
                                          },
                                          model: {
                                            value: _vm.userData[field.id].main,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.userData[field.id],
                                                "main",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "userData[field.id].main",
                                          },
                                        }),
                                        _c(
                                          "transition",
                                          { attrs: { name: "fade-out" } },
                                          [
                                            _vm.errorOnAddress
                                              ? _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "text-brandRed text-xs",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Please select a valid address from the dropdown"
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "grid md:grid-cols-2 mt-4 gap-4",
                                          },
                                          [
                                            _c("Select", {
                                              staticClass: "w-full",
                                              attrs: {
                                                placeholder: "State",
                                                options: _vm.states,
                                                reduce: function (category) {
                                                  return category.name
                                                },
                                                label: "name",
                                                value:
                                                  _vm.userData[field.id].state,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.handleStateInput(
                                                    $event,
                                                    field.id
                                                  )
                                                },
                                              },
                                            }),
                                            _c("Select", {
                                              staticClass: "w-full",
                                              attrs: {
                                                placeholder: "LGA",
                                                options: _vm.lga,
                                                reduce: function (category) {
                                                  return category.name
                                                },
                                                label: "name",
                                              },
                                              model: {
                                                value:
                                                  _vm.userData[field.id].lga,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.userData[field.id],
                                                    "lga",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "userData[field.id].lga",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          }),
                        ],
                        2
                      )
                    }
                  ),
                  0
                ),
                _c(
                  "div",
                  { staticClass: "explainer" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "explainer-heading flex items-center justify-between",
                      },
                      [
                        _c("p", [_vm._v("What we use your information for")]),
                        _c(
                          "div",
                          {
                            staticClass: "flex -items-center",
                            on: {
                              click: function ($event) {
                                _vm.showExplainer = !_vm.showExplainer
                              },
                            },
                          },
                          [
                            _c("p", { staticClass: "text-sm" }, [
                              _vm._v(
                                _vm._s(_vm.showExplainer ? "Hide" : "Show")
                              ),
                            ]),
                            _c("svg-icon", {
                              staticClass: "cursor-pointer",
                              attrs: {
                                color: "#0EA2F5",
                                type: "mdi",
                                path: _vm.svgPath.arrowDown,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c("AnimateHeight", [
                      _vm.showExplainer
                        ? _c("div", [
                            _c("div", { staticClass: "explainer-body" }, [
                              _c("p", [
                                _vm._v(
                                  "We use your information to get to know you as a customer."
                                ),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                    ]),
                  ],
                  1
                ),
              ]),
              _c("Button", {
                staticClass: "mt-12",
                attrs: {
                  width: "w-full",
                  text: "Continue",
                  loading: _vm.formLoading,
                  disabled: _vm.validateButton,
                },
                on: { click: _vm.handleSubmitForm },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "Modal",
        {
          attrs: { display: _vm.showReassurePrompt },
          on: {
            close: function ($event) {
              _vm.showReassurePrompt = false
            },
          },
        },
        [
          _c(
            "h5",
            {
              staticClass:
                "text-lg text-center md:text-xl primary-text font-bold",
            },
            [_vm._v("Are you sure you want to skip this step?")]
          ),
          _c("p", { staticClass: "mt-8 text-grey text-center" }, [
            _vm._v("Your details have not been verified."),
          ]),
          _c("Button", {
            staticClass: "mt-12",
            attrs: { width: "w-full", text: "No, continue to verify account" },
            on: {
              click: function ($event) {
                _vm.showReassurePrompt = false
              },
            },
          }),
          _c(
            "p",
            {
              staticClass:
                "text-center mt-4 font-semibold text-sm cursor-pointer",
              on: {
                click: function ($event) {
                  _vm.handleClose()
                  _vm.showReassurePrompt = false
                },
              },
            },
            [_vm._v(" Yes, skip this step ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }