<template>
  <div>
    <largeModal :display="display" background="#ffffff" @close="showReassurePrompt = true">
      <!-- <BackNav /> -->
      <div class="lg:w-3/5 mx-auto">
        <!-- <h5 class="md:text-2xl text-xl text-center font-bold primary-text leading-10">{{ details[0].label }}</h5> -->
        <p class="text-center text-grey md:text-lg">{{ details[currentActiveForm].description }}</p>
        <div class="mt-4">
          <div>
            <div v-for="details in details[currentActiveForm].sections" :key="details.label">
              <p class="text-grey text-xs my-4">{{ details.label }}</p>
              <!-- <component :is="inputComponentToRender(field.inputType)" v-for="field in details.fields" :key="field.id" class="mt-4" width="w-full"/> -->
              <div v-for="field in details.fields" :key="field.id" class="mt-4">
                <Input
                  v-if="
                    field.field.inputType === formTypes.text ||
                    field.field.inputType === formTypes.number ||
                    field.field.inputType === formTypes.email
                  "
                  width="w-full"
                  v-model="userData[field.id]"
                  :placeholder="field.field.hintText"
                  :label="field.field.label"
                  :required="field.field.required"
                  :type="
                    field.field.inputType === formTypes.number
                      ? 'number'
                      : field.field.inputType.toLowerCase()
                  "
                  :errorText="handleErrorText(field)"
                  :validation="handleInputValidation(field)"
                />
                <TelInput
                  v-if="
                    field.field.inputType === formTypes.phone
                  "
                  width="w-full"
                  class="mt-4"
                  v-model="userData[field.id]"
                  :required="field.field.required"
                  :placeholder="field.field.hintText"
                  :label="field.field.label"
                  :errorText="handleErrorText(field)"
                />
                <CurrencyInput
                  v-if="field.field.inputType === formTypes.amount"
                  v-model="userData[field.id]"
                  width="w-full"
                  :placeholder="field.field.hintText"
                  :label="field.field.label"
                  :required="field.field.required"
                  :errorText="handleErrorText(field)"
                />
                <Upload
                  v-if="field.field.inputType === formTypes.upload"
                  v-model="userData[field.id]"
                  :text="field.field.hintText"
                  :label="field.field.label"
                  :requird="field.field.required"
                  :fileType="['png', 'jpg', 'jpeg']"
                />
                <div v-if="field.field.inputType === formTypes.option" :key="field.field.label">
                  <MultiSelect
                    v-if="field.field.multiSelect"
                    :options="field.field.options"
                    :formLabel="field.field.label"
                    :placeholder="field.field.hintText"
                    :key="field.field.label"
                    :required="field.field.required"
                    v-model="userData[field.id]"
                    :reduce="(item) => item.id"
                  />
                  <Select
                    v-else
                    :value="userData[field.id]"
                    @input="handleSelectInput($event, field.id)"
                    returnValue="array"
                    :options="field.field.options"
                    :formLabel="field.field.label"
                    :placeholder="field.field.hintText"
                    :reduce="(item) => item.id"
                    :required="field.field.required"
                  />
                </div>
                <div v-if="field.field.inputType === formTypes.address">
                  <Input
                    :label="field.field.label"
                    :placeholder="field.field.hintText"
                    class="mt-4"
                    id="address"
                    type="text"
                    width="w-full"
                    @blur="validateAddress()"
                    @focus="handleFocus(field.id)"
                    @load="googleAutocomplete"
                    v-model="userData[field.id].main"
                    :required="field.field.required"
                  />
                  <transition name="fade-out">
                    <p v-if="errorOnAddress" class="text-brandRed text-xs">Please select a valid address from the dropdown</p>
                  </transition>
                  <div class="grid md:grid-cols-2 mt-4 gap-4">
                    <Select
                      class="w-full"
                      placeholder="State"
                      :options="states"
                      :reduce="(category) => category.name"
                      label="name"
                      :value="userData[field.id].state"
                      @input="handleStateInput($event, field.id)"
                    />
                    <Select
                      class="w-full"
                      placeholder="LGA"
                      :options="lga"
                      :reduce="(category) => category.name"
                      label="name"
                      v-model="userData[field.id].lga"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="explainer">
            <div class="explainer-heading flex items-center justify-between">
              <p>What we use your information for</p>
              <div class="flex -items-center" @click="showExplainer = !showExplainer">
                <p class="text-sm">{{ showExplainer ? "Hide" : "Show" }}</p>
                <svg-icon class="cursor-pointer" color="#0EA2F5" type="mdi" :path="svgPath.arrowDown"></svg-icon>
              </div>
            </div>
            <AnimateHeight>
              <div v-if="showExplainer">
                <div class="explainer-body">
                  <p>We use your information to get to know you as a customer.</p>
                </div>
              </div>
            </AnimateHeight>
          </div>
        </div>
        <Button class="mt-12" width="w-full" text="Continue" @click="handleSubmitForm" :loading="formLoading" :disabled="validateButton" />
      </div>
      <template v-slot:title>
        <h5 class="md:text-2xl text-xl text-center font-bold primary-text leading-10 w-full" v-if="details.length">
          {{ details[currentActiveForm].label }}
        </h5>
      </template>
    </largeModal>
    <Modal :display="showReassurePrompt" @close="showReassurePrompt = false">
      <h5 class="text-lg text-center md:text-xl primary-text font-bold">Are you sure you want to skip this step?</h5>
      <p class="mt-8 text-grey text-center">Your details have not been verified.</p>
      <Button class="mt-12" width="w-full" text="No, continue to verify account" @click="showReassurePrompt = false" />
      <p
        class="text-center mt-4 font-semibold text-sm cursor-pointer"
        @click="
          handleClose();
          showReassurePrompt = false;
        "
      >
        Yes, skip this step
      </p>
    </Modal>
  </div>
</template>
<script>
  import { mapActions, mapState } from "vuex";
  import { Input, Select, Upload, CurrencyInput, MultiSelect, TelInput } from "@/UI/Input";
  import { Button } from "@/UI/Button";
  import { BackNav } from "@/UI/Navigation";
  import { mdiChevronDown, mdiAlertCircleOutline } from "@mdi/js";
  import AnimateHeight from "@/UI/AnimateHeight";
  import { FORM_TYPES } from "@/utils/sharedData/home";
  import Location from "@/database/location.json";
  import axios from "@/plugins/axios";
  import { LargeModal, Modal } from "@/UI/Modals";
  import Hr from "@/UI/Hr";
  import { DASHBOARD_STEPS } from "@/utils/sharedData/home";
  export default {
    props: {
      display: {
        type: Boolean,
        default: false,
        required: false,
      },
    },
    components: {
      Input,
      Select,
      Button,
      BackNav,
      Hr,
      AnimateHeight,
      Upload,
      CurrencyInput,
      MultiSelect,
      LargeModal,
      Modal,
      TelInput
    },
    data: () => ({
      formTypes: FORM_TYPES,
      showExplainer: false,
      formLoading: false,
      svgPath: {
        arrowDown: mdiChevronDown,
        warning: mdiAlertCircleOutline,
      },
      // details: [],
      userData: {},
      location: Location,
      valid: {
        address: false,
      },
      isAddressValid: false,
      errorOnAddress: false,
      lga: [],
      showReassurePrompt: false,
      fieldsToSubmitAsArray: [],
      currentActiveForm: 0,
    }),
    computed: {
      ...mapState({
        details: (state) => state.dashboard?.personalDetails?.dynamicForm,
        displayPersonalDetails: (state) => state?.dashboard?.personalDetails?.display,
        customerTodos: (state) => state?.dashboard?.dashboardData?.customerTodos,
      }),
      states() {
        return this.location.map((location) => location.state);
      },
      validateButton() {
        // create a map of object where the id of each input field is tied to their validation pattern
        // if it doesn't have a validation pattern but required is true, the default validation patten should check for the length then.
        // if (this.details.length) {
        let rules = {};
        this.details[this.currentActiveForm].sections.forEach((item) => {
          if (item.fields.length) {
            item.fields.forEach((field) => {
              if (field.field.required && field.field.validationPattern) {
                rules[field.id] = new RegExp(field.field.validationPattern).test(this.userData[field.id]);
              } else if (field.field.required) {
                if (field.field.inputType === this.formTypes.address) {
                  rules[field.id] = !Object.values(this.userData[field.id]).includes("");
                } else {
                  rules[field.id] = !!this.userData[field.id].length;
                }
              }
            });
          }
        });
        return Object.values(rules).includes(false);
      },
    },
    watch: {
      displayPersonalDetails(value) {
        if (value && !this.details.length) {
          this.getFormData();
        }
      },
    },
    methods: {
      ...mapActions("dashboard", [
        "getDynamicFormData",
        "handleShowPersonalDetails",
        "showCreditQualificationStep",
        "handleShowReminder",
        "getDashboardData",
      ]),
      ...mapActions("loading", ["setLoading"]),
      ...mapActions("notification", ["showAlert"]),
      handleClose() {
        this.handleShowPersonalDetails(false);
        this.setLoading({
          display: true,
          description: "Please wait...",
        });
        this.getDashboardData().then(() => {
          this.handleShowReminder(true);
          this.setLoading(false);
        });
      },
      handleInputValidation(field) {
        if (field?.field?.minimumInputLength && this.userData[field.id].length < field?.field?.minimumInputLength) {
          return false;
        } else if (field?.field?.maximumInputLength && this.userData[field.id].length > field?.field?.maximumInputLength) {
          return false;
        } else if (field?.field?.validationPattern) {
          return new RegExp(field.field.validationPattern).test(this.userData[field.id]);
        } else {
          return true;
        }
      },
      handleErrorText(field) {
        let errorText;
        if (field?.field?.minimumInputLength && this.userData[field.id].length < field?.field?.minimumInputLength) {
          errorText = `Characters cannot be less than ${field?.field?.minimumInputLength}`;
        } else if (field?.field?.maximumInputLength && this.userData[field.id].length > field?.field?.maximumInputLength) {
          errorText = `Characters cannot not be more than ${field?.field?.maximumInputLength}`;
        } else if (field?.field?.errorMessage) {
          errorText = field?.field?.errorMessage;
        } else {
          errorText = "Input not valid";
        }
        return errorText;
      },
      handleSelectInput(event, id) {
        this.userData[id] = event;
        // saving for reference of select input ids,  since they need to be sent back to the BE as an array
        if (!this.fieldsToSubmitAsArray.includes(id)) {
          this.fieldsToSubmitAsArray.push(id);
        }
      },
      getFormData() {
        this.setLoading({
          display: true,
          description: "Please wait...",
        });
        this.getDynamicFormData()
          .then((res) => {
            // this.details = res.data.data;
            let userData = {};
            this.details[this.currentActiveForm].sections.forEach((item) => {
              if (item.fields.length) {
                item.fields.forEach((field) => {
                  if (field.field.inputType === this.formTypes.address) {
                    // this.googleAutocomplete();
                    userData[field.id] = {
                      main: "",
                      lga: "",
                      state: "",
                    };
                  } else {
                    userData[field.id] = "";
                  }
                });
              }
            });
            this.userData = userData;
            // this.validateButton();
            // this.googleAutocomplete();
            this.setLoading(false);
          })
          .catch(() => {
            this.setLoading(false);
          });
      },
      handleSubmitForm() {
        this.formLoading = true;
        // setting al input types of select to have values have array
        let initialFormData = structuredClone(this.userData);
        Object.keys(this.userData).forEach((key) => {
          if (this.fieldsToSubmitAsArray.includes(key)) {
            initialFormData[key] = [initialFormData[key]];
          }
        });
        axios
          .post(this.details[this.currentActiveForm].submissionUrl, { values: initialFormData })
          .then(() => {
            this.formLoading = false;
            this.$store.commit("dashboard/REMOVE_COMPLETED_TODO", { key: DASHBOARD_STEPS.personalDetails });
           this.handleClose()
          })
          .catch(() => {
            this.formLoading = false;
          });
      },
      handleStateInput(event, id) {
        this.userData[id].state = event;
        this.getLga(event);
      },
      getLga(value) {
        this.location.forEach((state) => {
          if (state.state.name === value) {
            this.lga = state.state.locals;
          }
        });
      },
      googleAutocomplete(id) {
        // document.getElementById("address").placeholder = "";
        let options = {
          // types: ["geocode"],
          componentRestrictions: { country: "ng" },
        };
        this.autocomplete = new google.maps.places.Autocomplete(document.getElementById("address"), options);
        this.autocomplete.addListener("place_changed", () => {
          this.isAddressValid = true;
          this.validateAddress();
          let place = this.autocomplete.getPlace();
          // console.log(place);
          let ac = place.address_components;
          const street = {
            no: "",
            name: "",
            neighborhood: "",
          };

          ac.forEach((address) => {
            if (address.types[0] === "street_number") {
              street.no = address.long_name;
            }
            if (address.types[0] === "route") {
              street.name = address.long_name;
            }
            if (address.types[0] === "neighborhood") {
              street.neighborhood = address.long_name;
            }
            if (address.types[0] === "administrative_area_level_1") {
              if (address.long_name === "Federal Capital Territory") {
                address.long_name = "FCT";
              }
              this.userData[id].state = address.long_name.split(" State")[0];
            }
            if (address.types[0] === "administrative_area_level_2") {
              if (address.short_name === "AMAC") {
                address.long_name = "Municipal Area Council";
              }
              this.userData[id].lga = address.long_name;
            }
          });
          // console.log(this.address.lga);

          // this.address.address =
          //   street.no + " " + street.name + ", " + street.neighborhood;
          this.userData[id].main = place.name;
          if (this.userData[id].state === "" || this.userData[id].lga === "") {
            this.isAddressValid = false;
            this.showAlert({
              display: true,
              description: "Please select a valid street address from the dropdown",
              info: "Eg: 19 Babatunde Kuboye Street, Lagos",
              type: "error",
            });
          }
        });
      },
      validateAddress() {
        if (this.isAddressValid) {
          this.errorOnAddress = false;
          document.getElementById("address").style.borderColor = "#000";
        } else {
          this.errorOnAddress = true;
          document.getElementById("address").style.borderColor = "#C70039";
        }
      },
      handleFocus(id) {
        if (this.isAddressValid) {
          this.userData[id].main = "";
          this.userData[id].state = "";
          this.userData[id].lga = "";
        }
        document.getElementById("address").style.borderColor = "#000";
        this.errorOnAddress = false;
        this.isAddressValid = false;
        if (!this.autocomplete) {
          this.googleAutocomplete(id);
        }
      },
    },
  };
</script>
<style scoped>
  .explainer {
    background: #f5fbff;
    border-radius: 8px;
    padding: 1rem;
    margin-top: 1rem;
  }

  .explainer-heading p {
    font-size: 14px;
    color: #0ea2f5;
  }
  .explainer-body {
    padding: 18px 0;
    border-top: 1px solid #ededf0;
    border-bottom: 1px solid #ededf0;
    margin-top: 22px;
  }
  .explainer-body p {
    color: #6b6873;
    font-size: 14px;
  }
  .explainer-footer {
    padding-top: 16px;
  }
  .explainer-footer p {
    font-size: 14px;
    color: #6b6873;
  }
</style>
